<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    
    <template #left>
      <RouterLink
        :class="`bg-primary h-full flex items-center justify-center bg-${ Math.floor(Math.random() * 5) + 1 }`"
        style="width: calc(100vw - 620px)"
        to="/"
        aria-label="Visit homepage"
      >

        <!-- <VuesticLogo :height="28" start="#FFF" /> -->
        <!-- <img src="/logo.png" style="max-width: 60%; background: rgba(255,255,255, 0.4); opacity: 1;"/>-->
        <div style="width: 80%">
          <div style="width: 100%">
            <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 715.56 220.97">
              <g>
                <path class="cls-2" d="M303.81,158.39v24.4h-86.1V37.69h30v120.7h56.1Z"/>
                <path class="cls-2" d="M352.31,182.79h-30.2V37.69h30.2v145.1Z"/>
                <path class="cls-2" d="M507.01,37.69v145.1h-15.5c-2.33,0-4.3-.38-5.9-1.15-1.6-.77-3.17-2.08-4.7-3.95l-72.3-92c.2,2.33.35,4.63.45,6.9.1,2.27.15,4.37.15,6.3v83.9h-26.5V37.69h15.8c1.27,0,2.35.05,3.25.15.9.1,1.72.33,2.45.7.73.37,1.43.85,2.1,1.45.67.6,1.4,1.4,2.2,2.4l72.8,92.4c-.27-2.53-.47-4.98-.6-7.35-.13-2.37-.2-4.62-.2-6.75V37.69h26.5Z"/>
                <path class="cls-2" d="M677.61,110.19c0,10.6-1.79,20.43-5.35,29.5-3.57,9.07-8.6,16.9-15.1,23.5s-14.32,11.78-23.45,15.55c-9.13,3.77-19.27,5.65-30.4,5.65s-21.27-1.88-30.4-5.65c-9.13-3.77-16.97-8.95-23.5-15.55-6.53-6.6-11.58-14.43-15.15-23.5-3.57-9.07-5.35-18.9-5.35-29.5s1.78-20.42,5.35-29.45c3.57-9.03,8.62-16.85,15.15-23.45,6.53-6.6,14.37-11.78,23.5-15.55,9.13-3.77,19.27-5.65,30.4-5.65s21.27,1.88,30.4,5.65c9.13,3.77,16.95,8.97,23.45,15.6,6.5,6.63,11.53,14.47,15.1,23.5,3.57,9.03,5.35,18.82,5.35,29.35ZM646.91,110.19c0-7.6-1-14.42-3-20.45-2-6.03-4.88-11.17-8.65-15.4-3.77-4.23-8.33-7.47-13.7-9.7-5.37-2.23-11.45-3.35-18.25-3.35s-12.9,1.12-18.3,3.35c-5.4,2.23-9.98,5.47-13.75,9.7-3.77,4.23-6.65,9.37-8.65,15.4-2,6.03-3,12.85-3,20.45s1,14.52,3,20.55c2,6.03,4.88,11.15,8.65,15.35,3.77,4.2,8.35,7.42,13.75,9.65,5.4,2.23,11.5,3.35,18.3,3.35s12.88-1.12,18.25-3.35c5.37-2.23,9.93-5.45,13.7-9.65,3.77-4.2,6.65-9.32,8.65-15.35,2-6.03,3-12.88,3-20.55Z"/>
              </g>
              <path class="cls-1" d="M34.25,182.72L117.7,38.19l9.96,47.63s2.38,10.71-7.04,20.14l-86.36,76.76Z"/>
              <path class="cls-2" d="M117.7,38.19l83.46,144.53-46.23-15.19s-10.47-3.29-13.92-16.17l-23.3-113.17Z"/>
              <path class="cls-3" d="M201.14,182.72l-166.89.03,36.26-32.45s8.09-7.42,20.96-3.97l109.67,36.39Z"/>
            </svg>
          </div>

          <div style="width: 100%">
            <svg id="Ebene_3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 926.07 220.97">
              <g>
                <path class="cls-2" d="M355.18,110.19c0,10.6-1.79,20.43-5.35,29.5-3.57,9.07-8.6,16.9-15.1,23.5s-14.32,11.78-23.45,15.55c-9.13,3.77-19.27,5.65-30.4,5.65s-21.27-1.88-30.4-5.65c-9.13-3.77-16.97-8.95-23.5-15.55-6.54-6.6-11.58-14.43-15.15-23.5-3.57-9.07-5.35-18.9-5.35-29.5s1.78-20.42,5.35-29.45c3.57-9.03,8.62-16.85,15.15-23.45,6.53-6.6,14.37-11.78,23.5-15.55,9.13-3.77,19.27-5.65,30.4-5.65s21.27,1.88,30.4,5.65c9.13,3.77,16.95,8.97,23.45,15.6,6.5,6.63,11.53,14.47,15.1,23.5,3.56,9.03,5.35,18.82,5.35,29.35ZM324.48,110.19c0-7.6-1-14.42-3-20.45-2-6.03-4.88-11.17-8.65-15.4-3.77-4.23-8.33-7.47-13.7-9.7-5.37-2.23-11.45-3.35-18.25-3.35s-12.9,1.12-18.3,3.35c-5.4,2.23-9.98,5.47-13.75,9.7-3.77,4.23-6.65,9.37-8.65,15.4-2,6.03-3,12.85-3,20.45s1,14.52,3,20.55c2,6.03,4.88,11.15,8.65,15.35,3.77,4.2,8.35,7.42,13.75,9.65,5.4,2.23,11.5,3.35,18.3,3.35s12.88-1.12,18.25-3.35c5.37-2.23,9.93-5.45,13.7-9.65,3.77-4.2,6.65-9.32,8.65-15.35,2-6.03,3-12.88,3-20.55Z"/>
                <path class="cls-2" d="M407.38,61.19v40.1h52.2v23.6h-52.2v57.9h-30.2V37.69h92.4v23.5h-62.2Z"/>
                <path class="cls-2" d="M520.58,61.19v40.1h52.2v23.6h-52.2v57.9h-30.2V37.69h92.4v23.5h-62.2Z"/>
                <path class="cls-2" d="M633.78,182.79h-30.2V37.69h30.2v145.1Z"/>
                <path class="cls-2" d="M762.97,147.09c1.67,0,3.06.6,4.2,1.8l11.9,12.7c-5.87,7.47-13.15,13.13-21.85,17-8.7,3.87-19.05,5.8-31.05,5.8-10.87,0-20.63-1.85-29.3-5.55-8.67-3.7-16.04-8.85-22.1-15.45s-10.73-14.43-14-23.5c-3.27-9.07-4.9-18.97-4.9-29.7,0-7.2.78-14.05,2.35-20.55,1.56-6.5,3.85-12.48,6.85-17.95,3-5.47,6.62-10.4,10.85-14.8,4.23-4.4,9.02-8.15,14.35-11.25s11.15-5.47,17.45-7.1c6.3-1.63,13.02-2.45,20.15-2.45,5.33,0,10.38.47,15.15,1.4,4.77.93,9.22,2.23,13.35,3.9,4.13,1.67,7.95,3.68,11.45,6.05,3.5,2.37,6.65,4.95,9.45,7.75l-10.1,13.8c-.6.87-1.37,1.63-2.3,2.3-.94.67-2.2,1-3.8,1s-3.22-.57-4.85-1.7c-1.63-1.13-3.63-2.4-6-3.8-2.37-1.4-5.33-2.67-8.9-3.8-3.57-1.13-8.08-1.7-13.55-1.7-6.07,0-11.63,1.1-16.7,3.3-5.07,2.2-9.42,5.38-13.05,9.55-3.63,4.17-6.45,9.28-8.45,15.35-2,6.07-3,12.97-3,20.7s1.08,14.75,3.25,20.85c2.17,6.1,5.13,11.25,8.9,15.45,3.77,4.2,8.17,7.38,13.2,9.55,5.03,2.17,10.42,3.25,16.15,3.25,3.4,0,6.48-.17,9.25-.5,2.77-.33,5.33-.9,7.7-1.7,2.37-.8,4.6-1.83,6.7-3.1,2.1-1.27,4.21-2.87,6.35-4.8.73-.6,1.5-1.1,2.3-1.5.8-.4,1.66-.6,2.6-.6Z"/>
                <path class="cls-2" d="M826.17,61.19v37.3h48.4v22.7h-48.4v38.1h62.2v23.5h-92.4V37.69h92.4v23.5h-62.2Z"/>
              </g>
              <path class="cls-1" d="M31.52,182.72L114.97,38.19l9.96,47.63s2.38,10.71-7.04,20.14L31.52,182.72Z"/>
              <path class="cls-2" d="M114.97,38.19l83.46,144.53-46.23-15.19s-10.47-3.29-13.92-16.17l-23.3-113.17Z"/>
              <path class="cls-3" d="M198.41,182.72l-166.89.03,36.26-32.45s8.09-7.42,20.96-3.97l109.67,36.39Z"/>
            </svg>
          </div>
        </div>
      </RouterLink>
    </template>

    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div style="display: flex; justify-content: center; padding: 40px;">
        <img src="/icon.png" style="max-width: 50%; background: rgba(255,255,255, 0.4); opacity: 1;"/>
      </div>

      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <!--
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <VuesticLogo class="mb-2" start="#0E41C9" />
            </RouterLink>
            -->
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>

<style lang="scss">
  .bg-1 {
    background-image: url(/img/caro/caro_1.jpg);
    background-size: cover;
  }
  .bg-2 {
    background-image: url(/img/caro/caro_2.jpg);
    background-size: cover;
  }
  .bg-3 {
    background-image: url(/img/caro/caro_3.jpg);
    background-size: cover;
  }
  .bg-4 {
    background-image: url(/img/caro/caro_4.jpg);
    background-size: cover;
  }
  .bg-5 {
    background-image: url(/img/caro/caro_5.jpg);
    background-size: cover;
  }
  .bg-6 {
    background-image: url(/img/caro/caro_6.jpg);
    background-size: cover;
  }

  .cls-1 {
    fill: #ee7577;
  }

  .cls-2 {
    fill: #e72585;
  }

  .cls-3 {
    fill: #78398e;
  }
</style>