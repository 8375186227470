export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'dashboard',
      },
    },

    {
      displayName: 'menu.assets',
      permissions: ['admin', 'general', 'assets'],
      meta: {
        icon: 'apartment',
      },
      children: [
        {
          name: 'company',
          displayName: 'menu.company',
          permissions: ['admin', 'company'],
        },
        {
          name: 'customer',
          displayName: 'menu.customers',
          permissions: ['admin', 'customers'],
        },
        {
          name: 'supplier',
          displayName: 'menu.supplier',
          permissions: ['admin', 'supplier'],
        },
      ]
    },
    
    /*
    {
      displayName: 'menu.compounds_baths',
      permissions: ['admin', 'compounds-baths'],
      meta: {
        icon: 'precision_manufacturing',
      },
      children: [
        {
          name: 'compound',
          displayName: 'menu.compound',
          permissions: ['admin', 'compounds'],
        },
        {
          name: 'bath',
          displayName: 'menu.baths',
          permissions: ['admin', 'baths'],
        },
      ]
    },
    */

    {
      displayName: 'menu.vacation_planning',
      permissions: ['admin', 'vacation-planning'],
      meta: {
        icon: 'houseboat',
      },
      children: [
        {
          name: 'vacation-planning-overview',
          displayName: 'menu.vacation_planning_overview',
          permissions: ['admin', 'vacation-planning-overview'],
        },
        {
          name: 'vacation-planning-application',
          displayName: 'menu.vacation_planning_application',
          permissions: ['admin', 'vacation-planning-application'],
        },
        {
          name: 'vacation-planning-examination',
          displayName: 'menu.vacation_planning_examination',
          permissions: ['admin', 'vacation-planning-examination'],
        },
      ]
    },

    /*
    {
      displayName: 'menu.tasks',
      permissions: ['admin', 'tasks'],
      meta: {
        icon: 'add_task',
      },
      children: [
        {
          name: 'tasks-overview',
          displayName: 'menu.tasks_overview',
          permissions: ['admin', 'tasks-overview'],
        },
        {
          name: 'tasks-kanban',
          displayName: 'menu.tasks_kanban',
          permissions: ['admin', 'tasks-kanban'],
        },
        {
          name: 'tasks-my-tasks',
          displayName: 'menu.tasks_my_tasks',
          permissions: ['admin', 'tasks-my-tasks'],
        },
      ]
    },
    */

    {
      displayName: 'menu.time_tracking',
      name: 'time_tracking_container',
      permissions: ['admin', 'time-tracking'],
      meta: {
        icon: 'schedule',
      },
      children: [
        {
          name: 'time-tracking-overview',
          displayName: 'menu.time_tracking_overview',
          permissions: ['admin', 'time-tracking-overview'],
        },
        {
          name: 'time-tracking-track',
          displayName: 'menu.time_tracking_track',
          permissions: ['admin', 'time-tracking-track'],
        },
        {
          name: 'terminal',
          displayName: 'menu.time_tracking_terminal',
          permissions: ['admin', 'time-tracking-terminal'],
        },
      ]
    },

    {
      displayName: 'menu.articles',
      name: 'article_container',
      permissions: ['admin', 'articles'],
      meta: {
        icon: 'inventory',
      },
      children: [
        {
          name: 'article',
          displayName: 'menu.articles_overview',
          permissions: ['admin', 'articles-overview'],
        },
        {
          name: 'category',
          displayName: 'menu.articles_categories',
          permissions: ['admin', 'articles-categories'],
        },
        {
          name: 'surface',
          displayName: 'menu.articles_surfaces',
          permissions: ['admin', 'articles-surfaces'],
        },
        {
          name: 'material',
          displayName: 'menu.articles_materials',
          permissions: ['admin', 'articles-materials'],
        },
        {
          name: 'production-instructions',
          displayName: 'menu.articles_production_instructions',
          permissions: ['admin', 'articles-production-instructions'],
        },
      ]
    },

    {
      displayName: 'menu.orders',
      name: 'processing',
      permissions: ['admin', 'orders'],
      meta: {
        icon: 'list_alt',
      },
      children: [
        {
          name: 'orders-overview',
          displayName: 'menu.orders_overview',
          permissions: ['admin', 'orders-overview'],
        },
        {
          name: 'orders-offers',
          displayName: 'menu.orders_offers',
          permissions: ['admin', 'orders-offers'],
        },
        {
          name: 'orders-orders',
          displayName: 'menu.orders_orders',
          permissions: ['admin', 'orders-orders'],
        },
        /*
        {
          name: 'orders-planning',
          displayName: 'menu.orders_planning',
          permissions: ['admin', 'orders-planning'],
        },
        */
        {
          name: 'orders-delivery-slips',
          displayName: 'menu.orders_delivery_slips',
          permissions: ['admin', 'orders-delivery-slips'],
        },
      ]
    },

    /*
    {
      displayName: 'menu.warehouse_management',
      permissions: ['admin', 'warehouse-management'],
      meta: {
        icon: 'warehouse',
      },
      children: [
        {
          name: 'wms-overview',
          displayName: 'menu.warehouse_management_overview',
          permissions: ['admin', 'warehouse-management-overview'],
        },
        {
          name: 'wms-management',
          displayName: 'menu.warehouse_management_management',
          permissions: ['admin', 'warehouse_management-management'],
        },
        {
          name: 'wms-administration',
          displayName: 'menu.warehouse_management_administration',
          permissions: ['admin', 'warehouse_management-administration'],
        },
      ]
    },
    */

    {
      displayName: 'menu.accounting',
      permissions: ['admin', 'accounting'],
      meta: {
        icon: 'calculate',
      },
      children: [
        {
          name: 'invoice',
          displayName: 'menu.invoice',
          permissions: ['admin', 'invoice'],
        },

        /*
        {
          name: 'reminder',
          displayName: 'menu.payment_reminders',
          permissions: ['admin', 'accounting-payment-reminders'],
        },
        {
          name: 'dunning',
          displayName: 'menu.dunnings',
          permissions: ['admin', 'accounting-dunnings'],
        },
        {
          name: 'bank-transfers',
          displayName: 'menu.bank_transfers',
          permissions: ['admin', 'banktransfers'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'export',
          displayName: 'menu.export',
          permissions: ['admin', 'accounting-export'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        */
      ]
    },

    {
      name: 'media',
      displayName: 'menu.files',
      permissions: ['admin', 'files'],
      meta: {
        icon: 'cloud_upload',
      },
    },

    {
      displayName: 'menu.operational_planning',
      permissions: ['admin', 'operational-planning'],
      meta: {
        icon: 'pending_actions',
      },
      children: [
        {
          name: 'operational-planning-diary',
          displayName: 'menu.operational_planning_diary',
          permissions: ['admin', 'operational-planning-diary'],
        },
        {
          name: 'operational-planning-maintenance-plans',
          displayName: 'menu.operational_planning_maintenance_plans',
          permissions: ['admin', 'operational-planning-maintenance-plans'],
        },
        {
          name: 'operational-planning-cleaning-schedules',
          displayName: 'menu.operational_planning_cleaning_schedules',
          permissions: ['admin', 'operational-planning-cleaning-schedules'],
        },
      ]
    },

    /*
    {
      name: 'settings',
      displayName: 'menu.settings',
      permissions: ['admin', 'settings'],
      meta: {
        icon: 'settings',
      },
      children: [
        {
          name: 'wip',
          displayName: 'menu.settings_general',
          permissions: ['admin', 'settings-general'],
        },
        {
          name: 'wip',
          displayName: 'menu.settings_unit',
          permissions: ['admin', 'settings-unit'],
        },
      ]
    },
    */

    {
      name: 'analytics',
      displayName: 'menu.analytics',
      permissions: ['admin', 'analytics'],
      meta: {
        icon: 'query_stats',
      },
      children: [
        /*
        {
          name: 'analytics/visitor',
          displayName: 'menu.analytics_visitor',
          permissions: ['admin', 'analytics'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        */
      ]
    },

    {
      displayName: 'menu.admin',
      permissions: ['admin'],
      meta: {
        icon: 'admin_panel_settings',
      },
      children: [
        {
          name: 'admin/user',
          displayName: 'menu.user',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'admin/settings',
          displayName: 'menu.settings',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },

  ] as INavigationRoute[],
}
